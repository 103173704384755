import React, { Component } from "react";
import { Link } from "gatsby";
import netlify from "../images/netlify.png";
import gatsby from "../../content/thumbnails/gatsby.png";

export default class Footer extends Component {
  render() {
    return (
      <footer className="footer container">
        <div>
          <a
            href="https://ko-fi.com/mindshaver"
            target="_blank"
            rel="noopener noreferrer"
          >
            Ko-Fi
          </a>
          <a
            href="https://www.patreon.com/rllivingston"
            target="_blank"
            rel="noopener noreferrer"
          >
            Patreon
          </a>
          <Link to="/newsletter">Newsletter</Link>
          <a
            href="https://www.youtube.com/channel/UC1SWhwWvwaHiiMi8QJJ1jbg/featured?view_as=subscriber"
            target="_blank"
            rel="noopener noreferrer"
          >
            YouTube
          </a>
        </div>
        <div>
          <a href="https://www.netlify.com/" title="Hosted by Netlify">
            <img
              src={netlify}
              target="_blank"
              rel="noopener noreferrer"
              className="footer-img"
              alt="GitHub"
            />
          </a>
          <a href="https://www.gatsbyjs.org/" title="Built with Gatsby">
            <img
              src={gatsby}
              target="_blank"
              rel="noopener noreferrer"
              className="footer-img"
              alt="GitHub"
            />
          </a>
        </div>
      </footer>
    );
  }
}
