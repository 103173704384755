const config = {
  siteTitle: "Robert Livingston",
  siteTitleShort: "Robert Livingston",
  siteTitleAlt: "Robert Livingston",
  siteLogo: "/logos/logo-1024.png",
  siteUrl: "https://www.robert-livingston.com",
  repo: "",
  pathPrefix: "",
  dateFromFormat: "YYYY-MM-DD",
  dateFormat: "MMMM Do, YYYY",
  siteDescription:
    "My personal website where I talk about tech, art, and life.",
  siteRss: "/rss.xml",
  googleAnalyticsID: "UA-158652804-1",
  postDefaultCategoryID: "Art",
  newsletter: "https://robertlivingston.substack.com",
  newsletterEmbed: "https://robertlivingston.substack.com/embed",
  userName: "Robert",
  userEmail: "rll@bu.edu",
  userTwitter: "RLLivingston",
  menuLinks: [
    {
      name: "About me",
      link: "/me/"
    },
    {
      name: "Blog",
      link: "/blog/"
    },
    {
      name: "Contact",
      link: "/contact/"
    }
  ],
  themeColor: "#3F80FF", // Used for setting manifest and progress theme colors.
  backgroundColor: "#ffffff"
};

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === "/") {
  config.pathPrefix = "";
} else {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, "")}`;
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === "/")
  config.siteUrl = config.siteUrl.slice(0, -1);

// Make sure siteRss has a starting forward slash
if (config.siteRss && config.siteRss[0] !== "/")
  config.siteRss = `/${config.siteRss}`;

module.exports = config;
